import axios from "@/axios.js";

export default {
  async fetchFoodMerchantCategories({ commit }) {
    try {
      const { data } = await axios.get(`/vancynet/food-merchant-category`);
      commit("setFoodMerchantCategories", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFoodMerchantCategory({ commit }, params) {
    try {
      const { data } = await axios.get(
        `/vancynet/food-merchant-category/${params}`
      );
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeFoodMerchantCategory({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/food-merchant-category`,
        params
      );
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateFoodMerchantCategory({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/food-merchant-category/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeFoodMerchantCategory({ dispatch }, params) {
    try {
      const { data } = await axios.delete(
        `/vancynet/food-merchant-category/${params}`
      );
      dispatch("fetchFoodMerchantCategories");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
